<template>
    <div>

        <!-- Banner -->
        <app-banner page-name="Store" />
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Store</span>
                    </div>
                    <div id="post-10" class="post-10 page type-page status-publish hentry">
                        <div class="entry-content">

                            <div class="hldr_main_box">
								<div class="widget-container classic-textwidget custom-classic-textwidget">
									<div class="main_box1" v-for="(product, index) in items" :key="`product-${index}`">
										<figure class="snip">
											<router-link :to="{ name: 'StoreItem', params: { id: product.id } }">
												<h4 style="text-align: center;">{{ product.name }}</h4>
												<div class="image">
													<img class="slide1" :src="`${apiDomain}${product.image}`" :alt="product.name" />
												</div>
											</router-link>
										</figure>
									</div>
								</div>
                            </div>

                            <!-- <div class="row">
                                <figure class="snip col-md-3" v-for="(product, index) in items" :key="`product-${index}`">
                                    <h4>{{ product.name }}</h4>
                                    <div class="image">
                                        <img class="slide1" :src="`${apiDomain}${product.image}`" :alt="product.name" />
                                    </div>

                                    <template v-if="product.hasOptions">
                                        <div class="options">
                                            <button v-for="(option, optionIndex) in product.options" :key="`product-${index}-options-${optionIndex}`">{{ option.size }}</button>
                                        </div>
                                    </template>
                                </figure>
                            </div> -->
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>

    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import { getStoreProducts } from '@/api/apiCalls'

export default {
    name: 'StorePage',
    data() {
        return {
            items: [],
            loading: true,
        }
    },
    components: {
        appBanner: banner,
    },
    beforeMount () {
        this.getData()
    },
    methods: {
        getData() {
            getStoreProducts().then(({ data }) => {
                this.items = data
            }).finally(() => {
                this.loading = false
            });
        }
    },
}
</script>

<style>
@media only screen and (max-width: 1010px) {
	[class^="main_box"] {
		width: 49%;
		margin: 0 auto;
		border: none!important;
	}

	[class^="main_box"] figure {
		border: 0!important;
		margin: 0 auto!important;
		display: inline-block!important;
	}
}
figure.snip{
  position: relative;
  float:left;
  overflow: hidden;   /* clipped content, no scroll */
  margin: 10px 1%;
  /* width:480px; */
  background: gray;
  color: #fff;
  text-align:center;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0,0,0, 0.15);   /* horizontal vertical blur color */
}
figure.snip *{           /* '*':select all elements */
  transition: all 0.5s ease;  /* smooth zoom on/out */
  box-sizing: border-box;
}
.image{                       /* left column */
  background-color: #000000;
  /* width:50%; */
  overflow:hidden;
}
.image img{
  max-width: 100%;
  margin-top: 40px;
  min-height: 300px;
  max-height: 300px;
}
figcaption{
  position:absolute;
  width:50%;
  right:0;
  top:50%;
  -webkit-transform: translateY(-50%); /* move up */
  transform: translateY(-50%);
  padding: 10px 35px;
}
figure.snip h4,
figure.snip p,
figure.snip .price{
  margin: 0 0 8px;                     /* top left bottom */
}
figure.snip h4{
  position: absolute;                  /* inside left column */
  width:100%;
  color:#ffffff;
  top:10px;
  text-transform: uppercase;          /* capitalize words */
  font-weight: 400;
  letter-spacing: 1px;                /* distance between letters */
  z-index:1;
}
figure.snip p{
  font-size:0.7em;
  font-weight: 500;
  line-height:1.6em;                  /* distance between lines */
}
figure.snip .price{
  color:#ffffff;
  font-size: 1.3em;
  opacity:0.8;
}

figure.snip .price s{
  display:inline-block;
  padding: 0 8px 0 0;
  font-size: 0.85em;
  color: #f39c12;
}

figure.snip .add-to-cart{
  text-decoration: none;
  position: absolute;
  color:#ffffff;
  bottom:0;
  right:0;
  font-weight: 600;
  line-height: 44px;                 /* bottom */
  font-size: 0.75em;
  text-transform: uppercase;
  width:50%;
  background-color: rgba(0, 0, 0, 0.3);
}

figure.snip img:hover{
  opacity:0.6;
  -webkit-transform: scale(1.3,1.3); /* zoom(x,y) */
  transform: scale(1.3,1.3);
}

figure.snip .add-to-cart:hover{
  background-color: black;
}

figure.snip button{
  /* position:absolute; */
  /* bottom:50px; */
  font-size:1em;
  color:white;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 3px;
  background-color: rgba(0,0,0,0.6);
  border: 2px solid white;
  outline: none;
}
figure.snip button:hover{
  background-color: white;
  color:black;
  border: 2px solid black;
}
.prev{
  left:60%;
}
.next{
  right:30px;
}

.animation-fly{
  position:absolute;
  z-index:99;
  width:100px;
  height:100px;
  object-fit:cover;
  border-radius:100%;
  transition: all 1s ease;
  animation: zoom 1.5s;    /* <=> animation-name: zoom; animation-duration: 1.5s */
}

@-webkit-keyframes zoom{           /* Google Chrome , Apple Safari */
  0% {transform: scale(0.4);}
  25% {transform: scale(1);}
  50% {transform: scale(1);}
  100% {transform: scale(0.5);}
}
@-moz-keyframes zoom{               /* Mozilla Firefox */
  0% {transform: scale(0.4);}
  25% {transform: scale(1);}
  50% {transform: scale(1);}
  100% {transform: scale(0.5);}
}
@-o-keyframes zoom{                      /*  Opera */
  0% {transform: scale(0.4);}
  25% {transform: scale(1);}
  50% {transform: scale(1);}
  100% {transform: scale(0.5);}
}
</style>